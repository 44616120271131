@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes SlowFloat {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-25px);
  }
}

#potatoBody {
  animation: SlowFloat 2s infinite alternate;
}
@keyframes tilt-n-move-shaking {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }

  25% {
    transform: translate(5px, 5px) rotate(5deg);
  }

  50% {
    transform: translate(0, 0) rotate(0eg);
  }

  75% {
    transform: translate(-5px, 5px) rotate(-5deg);
  }

  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes skew-y-shakeing {
  0% {
    transform: skewY(0deg);
  }

  25% {
    transform: skewY(10deg);
  }

  50% {
    transform: skewY(0deg);
  }

  75% {
    transform: skewY(10deg);
  }

  100% {
    transform: skewY(0deg);
  }
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(5deg);
  }

  50% {
    transform: rotate(0eg);
  }

  75% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes tentacle-sway {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(5%);
  }

  50% {
    transform: translateY(0);
  }

  75% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes vibrate {
  0% {
    transform: skewY(0deg);
  }

  5% {
    transform: skewY(1deg);
  }

  10% {
    transform: skewY(0deg);
  }

  15% {
    transform: skewY(1deg);
  }

  20% {
    transform: skewY(0deg);
  }

  100% {
    transform: skewY(0deg);
  }
}

@keyframes skew-y-shakeing-masque {
  0% {
    transform: skewY(0deg);
  }

  25% {
    transform: skewY(3deg);
  }

  50% {
    transform: skewY(0deg);
  }

  75% {
    transform: skewY(2deg);
  }

  100% {
    transform: skewY(0deg);
  }
}

@keyframes bubblesAnimation {
  0% {
    margin-top: 70px;
  }

  100% {
    margin-top: -100%;
  }
}

@keyframes sides {
  0% {
    margin-left: 0px;
  }

  100% {
    margin-left: 50px;
  }
}
